<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Çalışan Görev Türü</h3>
        </div>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>
      <div class="card-body">
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="9">
              <rs-text-field
                label="Çalışan Görev Türü"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import CompanyService from "@/core/services/company.service";
import { mapGetters } from "vuex";

export default {
  mixins: [hasForm, hasPermissions],
  computed: {
    ...mapGetters(["companyId"]),
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id && this.can("edit-company")) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        name: null,
        is_active: true,
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`worker-positions/${this.id}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      formData.company_id = this.companyId;

      if (this.id) {
        this.$api
          .put(`worker-positions/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              CompanyService.getWorkerPositions();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
            this.group_code = response.data.data.group_code;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`worker-positions`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              CompanyService.getWorkerPositions();
              return;
            }

            this.$router.replace({
              name: "my-company.worker-positions.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>
